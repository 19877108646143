<template>
  <div class="service">
    <div class="l-box">
      <div class="l-top-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>耕种区域面积</span>
        </div>
        <div ref="charts-row" class="charts-box"></div>
      </div>
      <div class="l-bottom-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>今日收割概况</span>
        </div>
        <div ref="charts-line" class="charts-box"></div>
      </div>
    </div>
    <div class="c-box">
      <div class="c-top-box" id="map"></div>
      <div class="c-bottom-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>近7日仓储</span>
        </div>
        <div ref="charts-bar" class="charts-box"></div>
      </div>
    </div>
    <div class="r-box">
      <div class="r-top-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>产能数据</span>
        </div>
        <div class="r-top-main">
          <div class="index-list">
            <div class="index-item" v-for="index in 10" :key="index">
              {{ index }}
            </div>
          </div>
          <div ref="charts-row2" class="charts-box"></div>
        </div>
      </div>
      <div class="r-bottom-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>群体概况</span>
        </div>
        <div ref="charts-pie" class="charts-box"></div>
      </div>
    </div>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { getAllMarkDataApi } from "@/request/api";
import { mapState } from "vuex";
import Overlay from "ol/Overlay";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import * as turf from "@turf/turf";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Collection from "ol/Collection";
import { Style, Fill, Stroke, Icon } from "ol/style";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import * as Interaction from "ol/interaction";
import hanshan from "@/assets/json/hanshan.json";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      chartsRow: null,
      chartsLine: null,
      chartsRow2: null,
      chartsPie: null,
      chartsBar: null,
      hanShanSource: new VectorSource({
        features: new GeoJSON().readFeatures(hanshan, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        }),
      }),
      cameraList: [],
      groupTitle: [],
      titleFeatures: [],
      allDataList: {},
      centerVal: 0,
      setTime: null,
      titleLayer: {},
      polygonsData: [],
      vsource: new VectorSource({}),

      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      userInfo: (state) => state.user.userInfo,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  mounted() {
    this.chartsRow = this.$echarts.init(this.$refs["charts-row"]);
    this.chartsLine = this.$echarts.init(this.$refs["charts-line"]);
    this.chartsRow2 = this.$echarts.init(this.$refs["charts-row2"]);
    this.chartsPie = this.$echarts.init(this.$refs["charts-pie"]);
    this.chartsBar = this.$echarts.init(this.$refs["charts-bar"]);
    this.initBarChart();
    this.initRowChart();
    this.initRow2Chart();
    this.initPieChart();
    this.initLineChart();
    this.getAllMarkData();
  },
  beforeDestroy() {
    if (this.setTime) {
      clearInterval(this.setTime);
      this.setTime = null;
    }
  },
  methods: {
    async getAllMarkData() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;
      this.cameraList.forEach((item, index) => {
        // 默认全选中
        this.groupTitle.push(item.group);
        if (item.type != "plot") {
          this.allDataList[item.group] = [];
          item.items.forEach((item2) => {
            let obj = {
              position: "",
              title: "",
              img: "",
              online: 1,
            };
            let iconType = "";
            if (item2.icon === "video") {
              iconType = "video";
            } else if (item2.icon === "weather") {
              iconType = "weather";
            } else {
              iconType = "water";
            }
            obj.title = item.group;
            obj.id = item2.id;
            obj.iconType = iconType;
            obj.icon = item2.icon;
            obj.position = [
              +item2.mark_data.split(",")[0],
              +item2.mark_data.split(",")[1],
            ];
            obj.img = `${this.$imgOnlineUrl}/index/${iconType}${item2.online}.png`;
            this.allDataList[item.group].push(obj);
            this.$forceUpdate();
            // 图标默认全显示
            this.cityPoint(this.allDataList[item.group]);
          });
          this.$forceUpdate();
        } else {
          this.polygonsData[index] = [];
          item.items.forEach((item2) => {
            // 处理坐标
            if (item2.mark_type == 3 && item2.mark_data) {
              let polygonsItemObj = { coord: [] };
              if (typeof item2.mark_data === "string") {
                item2.mark_data = JSON.parse(item2.mark_data);
              }
              item2.mark_data.forEach((item3) => {
                polygonsItemObj.coord.push([item3[0], item3[1]]);
              });
              polygonsItemObj.color = [item2.body_color, item2.border_color];
              polygonsItemObj.name = `polygon${item2.id}`;
              polygonsItemObj.id = item2.id;
              polygonsItemObj.title = item.group;
              let polygon = turf.polygon([item2.mark_data]);
              this.centerVal = turf.centroid(polygon).geometry.coordinates;
              this.polygonsData[index].push(polygonsItemObj);
              // 标题
              let titleMarkerObj = {
                text: item2.title,
                geometry: new Point(this.centerVal),
              };
              this.titleFeatures.push(titleMarkerObj);
            }
          });
          this.$forceUpdate();
        }
      });

      this.addTitles();
      this.showPolygon();
      this.setTime = setTimeout(() => {
        this.initMap();
      }, 80);
    },
    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");
      this.iconMarkLayer = new VectorLayer({
        zIndex: 3,
        source: this.vsource,
      });
      window.olMap = new Map({
        // 创建地图
        target: "map",
        layers: [
          new VectorLayer({
            zIndex: 3,
            maxZoom: 11,
            source: this.hanShanSource,
          }),
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer, //多边形
          this.titleLayer, //标题
          this.iconMarkLayer, //点
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: 10,
          maxZoom: 18,
          constrainResolution: true, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      });
      this.infoBox = document.getElementById("info-card");
      this.overLayer = new Overlay({
        element: this.infoBox,
        positioning: "center-center",
        offset: [0, -15],
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 4,
          color: y,
        }),
      });
      return style;
    },
    showPolygon(index = "wu") {
      // this.clearDraw();
      let hanshanpolygonsData = [];
      if (index == "wu") {
        hanshanpolygonsData = this.polygonsData;
      } else {
        hanshanpolygonsData = [this.polygonsData[index]];
      }
      hanshanpolygonsData.forEach((item) => {
        let array = item;
        let features = [];
        for (let i = 0; i < array.length; i++) {
          let coord = array[i].coord;
          let x = array[i].color[0];
          let y = array[i].color[1];
          let z = array[i].name;
          let f = new Feature({
            geometry: new Polygon([coord]),
            type: "isPolygon",
            id: array[i].id,
            title: array[i].title,
          });
          f.setStyle(this.featureStyle(x, y));
          f.setProperties({
            name: z,
          });
          features.push(f);
        }

        this.myPolygonSource.addFeatures(features);

        this.polygonLayer = new VectorLayer({
          zIndex: 3,
          minZoom: 8,
          source: this.myPolygonSource,
          style: new Style({
            fill: new Fill({
              color: "",
            }),
            stroke: new Stroke({
              width: 4,
              color: "",
            }),
          }),
        });
      });
    },

    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "rgba(22,133,144,.3)",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    cityPoint(list) {
      let features = [];
      let data = list;
      for (var i = 0; i < data.length; i++) {
        let feature = new Feature({
          geometry: new Point(data[i].position),
          type: "isPoint",
          citydata: data[i],
          title: data[i].title,
        });
        feature.setStyle(this.pointStyle(data[i].online, data[i].iconType));
        features.push(feature);
      }
      this.vsource.addFeatures(features);
    },

    pointStyle(online, iconType) {
      let imgUrl = iconType + online + ".png";
      let src = this.$imgOnlineUrl + "/index/" + imgUrl;
      let Styles = [];
      Styles.push(
        new Style({
          // 设置图标
          image: new Icon({
            src,
            anchor: [0.5, 0.5],
            scale: 1,
          }),
        })
      );
      return Styles;
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 13,
        source: new VectorSource({
          features: new Collection(
            // hanshanTitleData.forEach((item) => {
            // let arr = item;
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
            // })
          ),
          format: new GeoJSON(),
        }),
      });
    },
    initBarChart() {
      let option = {
        grid: {
          top: "4%",
          bottom: "26%",
          left: "8%",
          right: "8%",
        },

        legend: {
          left: "center",
          bottom: "1%",
          textStyle: {
            color: "#ccc",
          },
        },
        color: ["#8277E9", "#03B7E3", "#0380E3", "#5BF1F6"],
        tooltip: {
          trigger: "axis",
        },

        calculable: true,
        xAxis: [
          {
            type: "category",

            data: [
              "2024.08.01",
              "2024.08.02",
              "2024.08.03",
              "2024.08.04",
              "2024.08.05",
              "2024.08.06",
              "2024.08.07",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "transparent",
              },
            },
          },
        ],
        series: [
          {
            name: "水稻",
            type: "bar",
            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6],
          },
          {
            name: "麦子",
            type: "bar",
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
          },
          {
            name: "大豆",
            type: "bar",
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
          },
          {
            name: "油菜",
            type: "bar",
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
          },
        ],
      };
      this.chartsBar.setOption(option);
    },
    initPieChart() {
      let option = {
        grid: {
          top: "4%",
          bottom: "8%",
          left: "10%",
          right: "10%",
        },

        tooltip: {
          trigger: "item",
        },
        legend: {
          left: "center",
          bottom: "3%",
          textStyle: {
            color: "#fff",
          },
        },
        color: [
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFA600",
              },
              {
                offset: 1,
                color: "#FEDB65",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#0F64FF",
              },
              {
                offset: 1,
                color: "#2CDBFD",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#5F6CFE",
              },
              {
                offset: 1,
                color: "#6887F8",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#6DD400",
              },
              {
                offset: 1,
                color: "#45D7B2",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#026FB3",
              },
              {
                offset: 1,
                color: "#0CC8CF",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FFB375",
              },
              {
                offset: 1,
                color: "#FFD7B7",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#A485D4",
              },
              {
                offset: 1,
                color: "#C4A9E7",
              },
            ],
          },
        ],
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: 1048, name: "1" },
              { value: 735, name: "2" },
              { value: 580, name: "3" },
              { value: 484, name: "4" },
              { value: 300, name: "5" },
              { value: 300, name: "6" },
              { value: 300, name: "7" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.chartsPie.setOption(option);
    },
    initLineChart() {
      let option = {
        grid: {
          top: "4%",
          bottom: "8%",
          left: "10%",
          right: "10%",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        legend: {
          right: 20,
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 2,
          },
          textStyle: {
            color: "#fff",
          },
          data: ["实时", "平均"],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["1", "2", "3", "4", "5", "6", "7"],
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, .1)",
              type: [5, 10],
              dashOffset: 5,
            },
          },
        },
        series: [
          {
            name: "实时",
            data: [20, 32, 91, 34, 120, 30, 20],
            type: "line",
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "rgba(82, 175, 247, 1)",
              width: 4,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 8,
              shadowOffsetX: 10,
              shadowOffsetY: 15,
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(82, 175, 247, 0.68)",
                  },
                  {
                    offset: 1,
                    color: "rgba(82, 175, 247, 0.1)",
                  },
                ],
              },
            },
          },
          {
            name: "平均",
            data: [29, 40, 110, 48, 155, 46, 50],
            type: "line",
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "rgba(206, 174, 64, 1)",
              width: 4,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              shadowBlur: 8,
              shadowOffsetX: 10,
              shadowOffsetY: 15,
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(206, 174, 64, 0.68)",
                  },
                  {
                    offset: 1,
                    color: "rgba(206, 174, 64, 0.1)",
                  },
                ],
              },
            },
          },
          // {
          //   name: "实时2",
          //   data: [14, 45, 33, 76, 42, 26, 18],
          //   type: "line",
          //   smooth: true,
          //   symbol: "none",
          //   lineStyle: {
          //     color: "rgba(10, 237, 254, 1)",
          //     width: 4,
          //     shadowColor: "rgba(0, 0, 0, 0.5)",
          //     shadowBlur: 8,
          //     shadowOffsetX: 10,
          //     shadowOffsetY: 15,
          //   },
          //   areaStyle: {
          //     color: {
          //       type: "linearGradient",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         {
          //           offset: 0,
          //           color: "rgba(10, 237, 254, 0.68)",
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(10, 237, 254, 0.1)",
          //         },
          //       ],
          //     },
          //   },
          // },
        ],
      };
      this.chartsLine.setOption(option);
    },
    initRowChart() {
      let option = {
        grid: {
          top: "4%",
          bottom: "20%",
          left: "8%",
          right: "8%",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          bottom: "3%",
          textStyle: {
            color: "#fff",
          },
        },
        color: [
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(3, 223, 252, 1)",
              },
              {
                offset: 1,
                color: "rgba(27, 99, 204, 1)",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(96, 248, 249, 1)",
              },
              {
                offset: 1,
                color: "rgba(53, 225, 229, 1)",
              },
            ],
          },
          {
            type: "linearGradient",
            x: 1,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(64, 159, 249, 1)",
              },
              {
                offset: 1,
                color: "rgba(45, 92, 189, 1)",
              },
            ],
          },
        ],
        xAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#3B5565",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["A区", "B区", "C区", "D区", "E区", "F区", "G区"],
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "成熟度一",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [320, 302, 301, 334, 390, 330, 320],
          },
          {
            name: "成熟度二",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "成熟度三",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [220, 182, 191, 234, 290, 330, 310],
          },
        ],
      };
      this.chartsRow.setOption(option);
    },
    initRow2Chart() {
      let option = {
        grid: {
          top: "1%",
          left: "1%",
          bottom: "4%",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "value",
          position: "top",
          axisLabel: {
            show: false,
            lineStyle: {
              opacity: 0,
            },
          },
          splitLine: {
            lineStyle: {
              color: "transparent",
            },
          },
        },
        yAxis: {
          type: "category",
          axisLine: { show: false },
          axisTick: { show: false },
          data: [],
          axisLabel: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "transparent",
              type: [5, 10],
              dashOffset: 5,
            },
          },
        },
        itemStyle: {
          borderRadius: [0, 18, 18, 0],
        },
        color: [
          {
            type: "linearGradient",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(1, 251, 255, 0)",
              },
              {
                offset: 1,
                color: "rgba(1, 250, 255, 1)",
              },
            ],
          },
        ],
        series: [
          {
            barWidth: 18,
            type: "bar",
            label: {
              show: true,
              position: "right",
              color: "#4CD7EE",
              formatter: "{c}%",
            },
            data: [
              0.2,
              0.44,
              0.24,
              0.19,
              0.39,
              0.23,
              {
                value: 0.47,
                itemStyle: {
                  color: {
                    type: "linearGradient",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(250, 185, 42, 0)",
                      },
                      {
                        offset: 1,
                        color: "rgba(251, 185, 41, 1)",
                      },
                    ],
                  },
                },
              },
              0.18,
              0.28,
              0.38,
            ],
          },
        ],
      };
      this.chartsRow2.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  width: 100%;
  height: 100vh;
  background-color: #071a2c;
  padding: 80px 24px 0;
  display: flex;
  justify-content: space-between;
  .item-title {
    width: 100%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
    .icon {
      width: 12px;
      margin-right: 10px;
    }
  }
  .l-box {
    width: 30%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .l-top-box {
      width: 100%;
      height: 49%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 22px 0;
      background: radial-gradient(
        circle,
        rgba(27, 91, 159, 0.09) 0%,
        rgba(20, 46, 76, 0.59) 100%
      );
      .charts-box {
        width: 100%;
        height: 90%;
      }
    }

    .l-bottom-box {
      width: 100%;
      height: 49%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 22px 0;
      background: radial-gradient(
        circle,
        rgba(27, 91, 159, 0.09) 0%,
        rgba(20, 46, 76, 0.59) 100%
      );
      .charts-box {
        width: 100%;
        height: 90%;
      }
    }
  }
  .c-box {
    width: 39%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .c-top-box {
      width: 100%;
      height: 69%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      overflow: hidden;
    }
    .c-bottom-box {
      width: 100%;
      height: 30%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 22px 0;
      background: radial-gradient(
        circle,
        rgba(27, 91, 159, 0.09) 0%,
        rgba(20, 46, 76, 0.59) 100%
      );
      .charts-box {
        width: 100%;
        height: 90%;
      }
    }
  }
  .r-box {
    width: 30%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .r-top-box {
      width: 100%;
      height: 49%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 22px 0;
      .r-top-main {
        width: 100%;
        height: 95%;
        display: flex;
        padding: 0 20px;
        background: radial-gradient(
          circle,
          rgba(27, 91, 159, 0.09) 0%,
          rgba(20, 46, 76, 0.59) 100%
        );
        .index-list {
          width: 10%;
          height: 100%;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .index-item {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid #0292ac;
            color: #00fff4;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            margin-bottom: 8px;
            &:nth-of-type(1) {
              border: none;
              background: linear-gradient(180deg, #f6b303 0%, #b723dc 100%);
            }
            &:nth-of-type(2) {
              border: none;
              background: linear-gradient(180deg, #feda64 0%, #ff8b31 100%);
            }
            &:nth-of-type(3) {
              border: none;
              background: linear-gradient(180deg, #00cef1 0%, #0935d2 100%);
            }
          }
        }
        .charts-box {
          width: 90%;
          height: 100%;
        }
      }
    }

    .r-bottom-box {
      width: 100%;
      height: 49%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 22px 0;
      background: radial-gradient(
        circle,
        rgba(27, 91, 159, 0.09) 0%,
        rgba(20, 46, 76, 0.59) 100%
      );
      .charts-box {
        width: 100%;
        height: 90%;
      }
    }
  }
}
</style>
